import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import config from '../config';
import './FeedbackForm.scss';
import { useAnalytics } from './AnalyticsTracker';

function FeedbackForm({isFixed = true, customText = 'Send Feedback'}) {
  const { trackEvent } = useAnalytics();
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [isCapturingScreenshot, setIsCapturingScreenshot] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [screenshotUrl, setScreenshotUrl] = useState(null);
  const formRef = useRef(null);
  const iconRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      console.log(event)
      if (formRef.current && !formRef.current.contains(event.target) &&
          iconRef.current && !iconRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (screenshotUrl) {
        URL.revokeObjectURL(screenshotUrl);
      }
    };
  }, [screenshotUrl]);

  const captureScreenshot = async () => {
    setIsCapturingScreenshot(true);
    setIsOpen(false); // Hide the form before capturing
    
    // Wait for the next frame to ensure the form is hidden
    requestAnimationFrame(async () => {
      try {
        const canvas = await html2canvas(document.body);
        const screenshotData = canvas.toDataURL('image/png');
        setScreenshot(screenshotData);
        setScreenshotUrl(URL.createObjectURL(dataURItoBlob(screenshotData)));
      } catch (error) {
        console.error('Error capturing screenshot:', error);
      } finally {
        setIsCapturingScreenshot(false);
        setIsOpen(true); // Show the form again after capturing
      }
    });
  };

  // Function to convert data URI to Blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // Function to handle screenshot download
  const handleDownloadScreenshot = () => {
    if (screenshotUrl) {
      const link = document.createElement('a');
      link.href = screenshotUrl;
      link.download = 'feedback_screenshot.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const trackFeedbackOpenAction = (bool) => {
    trackEvent('FeedbackForm', 'FeedbackOpenAction', 'FeedbackOpenAction', bool);
  }

  const handleSubmit = async (e) => {
    trackEvent('FeedbackForm', 'FeedbackSubmitted', 'FeedbackSubmitted', '');
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('email', email);
      
      if (screenshot) {
        // Convert base64 to blob
        const response = await fetch(screenshot);
        const blob = await response.blob();
        formData.append('screenshot', blob, 'screenshot.png');
      }

      await axios.post(`${config.apiUrl}/feedback`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      
      setIsOpen(false);
      setTitle('');
      setDescription('');
      setEmail('');
      setScreenshot(null);
      // Optionally, show a success message
      alert('Feedback submitted successfully.');
    } catch (error) {
      setSubmitError('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`${isFixed ? 'feedback-container' : ''}`}>
      {isFixed ? 
      <div className={`feedback-icon ${isOpen ? 'active' : ''}`} onClick={() => {
        setIsOpen(!isOpen); trackFeedbackOpenAction(!isOpen);
      }} ref={iconRef}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
          <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2V6h2v4z"/>
        </svg>
      </div>
      : ''}
      {!isFixed ? 
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a role="button" className='btn btn-link text-center m-auto' onClick={() => {
          setIsOpen(!isOpen); trackFeedbackOpenAction(!isOpen);
        }} ref={iconRef}>{customText}</a>
      : ''}
      <div className={`feedback-popover ${!isFixed ? 'not-fixed' : ''} ${isOpen && !isCapturingScreenshot ? 'open' : ''}`} ref={formRef}>
        <h2>Submit Feedback</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              id="feedback-title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Title"
            />
          </div>
          <div className="form-group">
            <textarea
              id="feedback-description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              placeholder="Description"
            />
          </div>
          <div className="form-group">
            <input
              id="feedback-email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email (optional)"
            />
          </div>
          <div className="form-group screenshot-group">
            <button 
              type="button" 
              className="capture-screenshot" 
              onClick={captureScreenshot} 
              disabled={isCapturingScreenshot}
              title="Capture Screenshot"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                <path d="M9 3H15L17 5H21C22.1 5 23 5.9 23 7V19C23 20.1 22.1 21 21 21H3C1.9 21 1 20.1 1 19V7C1 5.9 1.9 5 3 5H7L9 3ZM12 18C14.76 18 17 15.76 17 13C17 10.24 14.76 8 12 8C9.24 8 7 10.24 7 13C7 15.76 9.24 18 12 18Z"/>
                <circle cx="12" cy="13" r="3.5"/>
              </svg>
            </button>
            {isCapturingScreenshot && <span>Capturing...</span>}
            {screenshot && (
              <div className="screenshot-actions">
                <span className="screenshot-captured">Screenshot captured!</span>
                <button type="button" onClick={handleDownloadScreenshot} className="download-screenshot">
                  Download Screenshot
                </button>
              </div>
            )}
          </div>
          {submitError && <p className="error">{submitError}</p>}
          <div className="button-group">
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FeedbackForm;
