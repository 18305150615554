import React, { useEffect, useState } from 'react';
import apiServiceInstance from './../services/load';

export default function GoogleAuthRedirectPage() {    
    const [error, setError] = useState(null);
    useEffect(() => {
        const url = new URL(window.location.href);
        const code = url.searchParams.get('code');
        apiServiceInstance.authByGoogleCode(code).then((data) => {
            localStorage.setItem('auth', JSON.stringify(data));
            window.location.href = '/user';
        }).catch((e) => {
            console.error(e);
            setError(e.message);
        });
    }, []);

    return (
        <>
            {!error ? <div>
                Closing this page ...
            </div> : ''}
            {error ? <div className='text-danger text-center mt-5'>{error}</div> : ''}
        </>
    )
}