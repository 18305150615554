import React, { useState, useEffect } from 'react';
import FeedbackForm from '../components/FeedbackForm';
import './FoodPurchaseDecisionMakerPage.scss';
import Uploader from '../components/Uploader';
import ProfileForm from '../components/ProfileForm';
import HeaderMenu from '../components/HeaderMenu';
import FooterSection from '../components/Footer';
import config from '../config';
import { useAnalytics } from './../components/AnalyticsTracker'; 
import { FaUsers } from 'react-icons/fa';
import SessionService from './../services/session';

function FoodPurchaseDecisionMakerPage() {
  const { trackEvent } = useAnalytics();
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  useEffect(() => {
    const savedData = localStorage.getItem('foodPurchaseData');
    if (savedData) {
      setFormData(prevData => ({
        ...prevData,
        ...JSON.parse(savedData)
      }));
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }

    const callbackForLeadStackWidget = () => {
      const targetDivId1 = 'lead_stack_widget';
      const targetWidgetId1 = '67346cbf7e2f675f49fffede';
      if (!document.getElementById(targetDivId1).children.length) {
          new window.LeadStackWidget(targetWidgetId1, 'full', (e, data) => {
              console.log(e, data);
          }, {}).show(targetDivId1);
      }
      // you can add render same or multiple widgets following above approach
    };
  
  // this should be called once
  (function loadLeadStackWidget(callback) {
      var loaded = false;
      const scriptElem = document.createElement('script');
      scriptElem.type = 'text/javascript';
      scriptElem.src = 'https://www.getleadstack.com/widget.js';
      scriptElem.onload = function () {
          if (!loaded) {
              loaded = true;
              if (window.LeadStackWidget) {
                  callback();
              }
          }
      };
      const existingElem = document.getElementsByTagName('script')[0];
      existingElem.parentNode?.insertBefore(scriptElem, existingElem);
  })(callbackForLeadStackWidget);
  }, []);

  const handleBackToEditProfile = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleBackToUploader = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const faqs = [
    {
      question: "What is FoodFit AI?",
      answer: "FoodFit AI is an intelligent food decision-making assistant that helps you make informed choices about various foods based on your personal health profile and dietary preferences."
    },
    {
      question: "How does FoodFit AI work?",
      answer: "FoodFit AI analyzes the nutritional information of foods using image recognition technology for packaged items, or nutritional databases for fresh produce and common dishes. It then compares this information to your personal health profile to provide personalized recommendations."
    },
    {
      question: "Is my personal health information secure?",
      answer: "Yes, your personal health information is stored locally on your device and is not transmitted to any external servers. We take your privacy seriously."
    },
    {
      question: "What types of foods can I use FoodFit AI for?",
      answer: "FoodFit AI can provide insights on a wide range of foods, including packaged items, fresh produce, and common dishes. For packaged foods, it works best with clear nutrition labels. For other foods, it relies on comprehensive nutritional databases."
    },
    {
      question: "How accurate is FoodFit AI?",
      answer: "While FoodFit AI strives for high accuracy using up-to-date nutritional databases and AI technology, it's important to note that it's a guide and not a substitute for professional medical or nutritional advice."
    },
    {
      question: "Can I update my health profile?",
      answer: "Yes, you can update your health profile at any time by clicking the 'Edit Profile' button on the main page. Keeping your profile current ensures more accurate recommendations."
    },
    {
      question: "What if FoodFit AI doesn't recognize a food item?",
      answer: "For packaged items, ensure the image is clear and well-lit. For other foods, you can manually search our database or input nutritional information if known. We continuously update our food database to improve recognition."
    },
    {
      question: "Can I use FoodFit AI for meal planning?",
      answer: "Yes! FoodFit AI can help you make informed decisions about individual foods, which can be very useful for planning balanced meals that align with your health goals."
    },
    {
      question: "Is FoodFit AI available offline?",
      answer: "Basic functionality and your personal profile are available offline. However, an internet connection is required for image processing, accessing the full food database, and receiving the most up-to-date recommendations."
    },
    {
      question: "How does FoodFit AI handle dietary restrictions or preferences?",
      answer: "FoodFit AI takes into account the dietary preferences and restrictions you specify in your profile, such as vegetarian, vegan, gluten-free, or specific allergies, when providing recommendations."
    }
  ];

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const handleGoogleSignIn = () => {
    // Implement your Google sign-in logic here
    console.log('Signing in with Google...');
    trackEvent('HomePage', 'GoogleSignIn', 'GoogleSignIn', '');
    setTimeout(() => {
      window.location.href = `${config.apiUrl}/google/auth?redirect=true`;
    });
  };

  const userCount = 25;
  
  return (
    <div className="food-purchase-decision-maker">
      <HeaderMenu></HeaderMenu>
      <div className="header">
        <h1 className="title">AI-Powered Food Shopping Assistant</h1>
        <p className="subtitle">
          FoodFitAI helps you make food purchasing decisions <span> based on your health profile. </span>
        </p>
        {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
        <button onClick={() => handleGoogleSignIn()} className="sing-in-button mt-5">
            <span>Sign In / Sign up with Google</span>
        </button>
        )}
        {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
          <button className="sing-in-button mt-5" onClick={() => window.location.href = '/user'}>
          Access your account 
          </button>
        )}
        <p className="hover-tip text-muted small fst-italic text-center mb-0">
          Start with 10 free credits - no cost to join
        </p>
        <div className="user-count text-center mt-2">
          <FaUsers className="user-icon" />
          <span className="user-count-text">
          Join {userCount}+ happy users!
          </span>
      </div>
      </div>
      <div className="how-to-use gradient-bg">
        <h2 className='h4 mb-4 text-dark'>Start Your FoodFitAI Journey</h2>
        <div className="steps-container">
          <div className="step">
            <div className="step-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="48" height="48">
                <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
              </svg>
            </div>
            <h3 className='h6 text-muted'><span className="step-circle bg-primary text-white me-2">1</span> Complete your health profile</h3>
            <p>Enter your health details, dietary preferences, and goals to personalize your experience.</p>
          </div>
          <div className="step">
            <div className="step-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="48" height="48">
                <path d="M3 4V1h2v3h3v2H5v3H3V6H0V4h3zm3 6V7h3V4h7l1.83 2H21c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V10h3zm7 9c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-3.2-5c0 1.77 1.43 3.2 3.2 3.2s3.2-1.43 3.2-3.2-1.43-3.2-3.2-3.2-3.2 1.43-3.2 3.2z"/>
              </svg>
            </div>
            <h3 className='h6 text-muted'><span className="step-circle bg-primary text-white me-2">2</span> Scan or Upload Food Products</h3>
            <p>Get instant AI recommendations on whether to buy based on your health profile.</p>
          </div>
        </div>
        
      </div>
      <h2 className='h3 text-dark'>Try FoodFitAI here</h2>
      {isEditing ? (
        <div>
          <ProfileForm handleBackToUploader={handleBackToUploader}></ProfileForm>
        </div>
      ) : ''}

      {!isEditing ? (
        <Uploader formData={formData} handleBackToEditProfile={handleBackToEditProfile}></Uploader>
      ) : ''}
      <div className='text-center'>
      {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
      <button onClick={() => handleGoogleSignIn()} className="sing-in-button mt-5 mx-auto mb-0">
            <span>Sign In / Sign up with Google</span>
        </button>
        )}
        {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
          <button className="sing-in-button mt-5" onClick={() => window.location.href = '/user'}>
          Access your account 
          </button>
        )}
        <p className="hover-tip text-muted small fst-italic text-center mb-4">
          Start with 10 free credits - no cost to join
        </p>
      </div>
      <div className="how-to-use gradient-bg p-4">
        <div id='lead_stack_widget'></div>
      </div>
     
      {/* <WaitList 
        title="Be a FoodFit AI Pioneer"
        subtitle="Get free early access when we release fully featured product and help shape our product!"
        buttonText="Join FoodFit AI Beta"
        successMessage="You're in! We'll notify you soon about your exclusive early access to FoodFit AI."
        placeholderText="Your email for early access"
      /> */}
      <section className="faqs">
        <h2>Frequently Asked Questions</h2>
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${openFaqIndex === index ? 'open' : ''}`}>
            <button className="faq-question" onClick={() => toggleFaq(index)}>
              {faq.question}
              <span className="faq-icon">{openFaqIndex === index ? '−' : '+'}</span>
            </button>
            <div className="faq-answer">
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </section>
      <footer className="feedback-footer">
        <div className="feedback-content">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="feedback-icon">
            <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/>
            <path d="M7 9h10v2H7zm0-3h10v2H7zm0 6h7v2H7z"/>
          </svg>
          <p>
            Have feedback? Reach out to the creator
            <a href="https://x.com/onkarjanwa" target="_blank" rel="noopener noreferrer">
              @onkarjanwa on X
            </a>
          </p>
        </div>
      </footer>
      <FeedbackForm />
      <FooterSection></FooterSection>
    </div>
  );
}

export default FoodPurchaseDecisionMakerPage;