import React from 'react';
import './WebToAppPage.scss';
import HeaderMenu from '../components/HeaderMenu';
import FeedbackForm from '../components/FeedbackForm';

const WebToAppPage = () => {
  return (
    <div className="food-purchase-decision-maker">
        <HeaderMenu></HeaderMenu>
        <div className="web-to-app">
          <h1>Install FoodFitAi as an App in Google Chrome</h1>
          <p>
            You can easily install FoodFitAi as an app on your mobile phone, desktop or laptop using Google Chrome. 
            This allows you to access the website quickly without opening a browser tab. Follow the steps below:
          </p>
          <ol>
            <li>
              <strong>Open Google Chrome:</strong> Make sure you are using the Google Chrome browser.
            </li>
            <li>
              <strong>Visit the Website:</strong> <a target='_blank' rel="noreferrer" href='https://foodfitai.com'>https://foodfitai.com</a>.
            </li>
            <li>
              <strong>Open the Chrome Menu:</strong> Click on the three vertical dots in the upper right corner of the browser window.
            </li>
            <li>
              <strong>Select "Install":</strong> In the dropdown menu, look for the option that says <strong>"Install [Website Name]"</strong>. 
              If you don't see this option, you may also find it under <strong>"More tools" &gt; "Create shortcut..."</strong>.
            </li>
            <li>
              <strong>Confirm Installation:</strong> A dialog will appear asking you to confirm the installation. 
              Click on the <strong>"Install"</strong> button.
            </li>
            <li>
              <strong>Access the App:</strong> Once installed, you can find the app in your applications menu or on your desktop. 
              You can also pin it to your taskbar for easy access.
            </li>
          </ol>
          <p>
            Now you can enjoy quick access to FoodFitAi as a standalone app! If you have any questions or need assistance, feel free to reach out at <strong>onkar@mattecurve.com</strong>.
          </p>
        </div>
        <FeedbackForm />
    </div>
  );
};

export default WebToAppPage;
