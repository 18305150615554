import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AnalyticsTracker from './components/AnalyticsTracker';
import FoodPurchaseDecisionMakerPage from './pages/FoodPurchaseDecisionMakerPage';
import UserPage from './pages/UserPage';
import GoogleAuthRedirectPage from './pages/GoogleAuthRedirectPage';
import TermsPage from './pages/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import WebToAppPage from './pages/WebToAppPage';

function App() {
  return (
    <Router>
      <AnalyticsTracker>
        <Routes>
          <Route path="/" element={<FoodPurchaseDecisionMakerPage />} />
          <Route path="/google-auth-redirect" element={<GoogleAuthRedirectPage />} />
          <Route path="/user" element={<UserPage />} />
          <Route path="/terms-conditions" element={<TermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/download-app" element={<WebToAppPage />} />
        </Routes>
      </AnalyticsTracker>
    </Router>
  );
}

export default App;
