import React, { useState, useEffect } from 'react';
import apiServiceInstance from './../services/load';
import UserProfileDataService from './../services/user';
import SessionService from './../services/session';
import './ProfileForm.scss';
import { useAnalytics } from './AnalyticsTracker'; 
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

function ProfileForm({ handleBackToUploader }) {
  const { trackEvent } = useAnalytics();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    heightFeet: '',
    heightInches: '',
    height: '', // New field
    weight: '', // New field
    activityLevel: '', // New field
    weightGoal: '',
    medicalCondition: '',
    allergies: '',
    diet: '',
    proteinIntake: false,
    preservatives: false,
    organicPreference: false,
    lowSugarSodium: false,
    avoidRefinedSugarSaturatedFat: false,
    highFiber: false, // New field
    lowCholesterol: false, // New field
    increasedCalcium: false, // New field
  });
  const nextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };
  const prevStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const [, setIsEditing] = useState(false);
  const [heightFeet, setHeightFeet] = useState('');
  const [heightInches, setHeightInches] = useState('');

  useEffect(() => {
    if (!SessionService.isLoggedIn()) {
      const savedData = localStorage.getItem('foodPurchaseData');
      if (savedData) {
        setFormData(prevData => ({
          ...prevData,
          ...JSON.parse(savedData)
        }));
      }
    } else {
      const healthProfiles = UserProfileDataService.getUserProfile()?.healthProfiles || [];
      if (healthProfiles.length) {
        setFormData(prevData => ({
          ...prevData,
          ...healthProfiles[0]
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (formData.height) {
      const totalInches = Math.round(parseFloat(formData.height) / 2.54);
      setHeightFeet(Math.floor(totalInches / 12).toString());
      setHeightInches((totalInches % 12).toString());
    }
  }, [formData.height]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    if (name === 'heightFeet') {
      setHeightFeet(value);
    } else if (name === 'heightInches') {
      setHeightInches(value);
    }
    
    const feet = parseInt(name === 'heightFeet' ? value : heightFeet) || 0;
    const inches = parseInt(name === 'heightInches' ? value : heightInches) || 0;
    const totalInches = feet * 12 + inches;
    const cm = Math.round(totalInches * 2.54);
    
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
      height: cm ? cm.toString() : ''
    }));
  };

  const handleSubmit = (e) => {
    trackEvent('ProfileForm', 'FormSubmitted', 'FormSubmitted', '');
    e.preventDefault();
    if (!SessionService.isLoggedIn()) {
      localStorage.setItem('foodPurchaseData', JSON.stringify(formData));
      setIsEditing(false);
      handleBackToUploader(e);
    } else {
      apiServiceInstance.setUserHealthProfile(formData).then((data) => {
        UserProfileDataService.saveUserProfile(data);
        setIsEditing(false);
        handleBackToUploader(e);
      });
    }
  };

  return (
    <div className="health-form-container container p-0">
      <div className="card">
        <div className="card-body">
          <h3 className="h4 text-dark card-title text-center mb-4">Complete your health profile</h3>
          <div className="progress mb-4">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${(currentStep / 4) * 100}%` }}
            aria-valuenow={currentStep}
            aria-valuemin="0"
            aria-valuemax="4"
          >
            Step {currentStep} of 4
          </div>
        </div>
          <form onSubmit={handleSubmit}>
            {currentStep === 1 && (
              <div>
                <h4 className="mb-3 h5">Step 1: Basic Information</h4>
                <div className="mb-3">
                  <label htmlFor="age" className="form-label">What's your age:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="age"
                    name="age"
                    value={formData.age}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="gender" className="form-label">What's your gender:</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="inlineRadio1" value="male" onChange={handleChange} checked={formData.gender === 'male'}  />
                      <label className="form-check-label" for="inlineRadio1">Male</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="inlineRadio2" value="female" onChange={handleChange} checked={formData.gender === 'female'}  />
                      <label className="form-check-label" for="inlineRadio2">Female</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="gender" id="inlineRadio3" value="other" onChange={handleChange} checked={formData.gender === 'other'}  />
                      <label className="form-check-label" for="inlineRadio3">Other</label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">What's your height:</label>
                  <div className="row">
                    <div className="col">
                      <select
                        className="form-select"
                        id="heightFeet"
                        name="heightFeet"
                        value={formData.heightFeet}
                        onChange={handleHeightChange}
                      >
                        {[...Array(9).keys()].map(i => (
                          <option key={i} value={i}>{i} ft</option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <select
                        className="form-select"
                        id="heightInches"
                        name="heightInches"
                        value={formData.heightInches}
                        onChange={handleHeightChange}
                      >
                        {[...Array(12).keys()].map(i => (
                          <option key={i} value={i}>{i} inch</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="weight" className="form-label">What's your weight (kg):</label>
                  <input
                    type="number"
                    className="form-control"
                    id="weight"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div>
                <h4 className="mb-3 h5">Step 2: Health Goals</h4>
                <div className="mb-3">
                  <label htmlFor="activityLevel" className="form-label">What's your activity level:</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="activityLevel" id="activityLevelInlineRadio1" value="sedentary" onChange={handleChange} checked={formData.activityLevel === 'sedentary'}  />
                      <label className="form-check-label" for="activityLevelInlineRadio1">Sedentary</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="activityLevel" id="activityLevelInlineRadio2" value="moderately active" onChange={handleChange} checked={formData.activityLevel === 'moderately active'}  />
                      <label className="form-check-label" for="activityLevelInlineRadio2">Moderately Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="activityLevel" id="activityLevelInlineRadio3" value="very active" onChange={handleChange} checked={formData.activityLevel === 'very active'}  />
                      <label className="form-check-label" for="activityLevelInlineRadio3">Very Active</label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="weightGoal" className="form-label">What's your weight goal:</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="weightGoal" id="weightGoalInlineRadio1" value="lose" onChange={handleChange} checked={formData.weightGoal === 'lose'}  />
                      <label className="form-check-label" for="weightGoalInlineRadio1">Lose Weight</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="weightGoal" id="weightGoalInlineRadio2" value="maintain" onChange={handleChange} checked={formData.weightGoal === 'maintain'}  />
                      <label className="form-check-label" for="weightGoalInlineRadio2">Maintain Weight</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="weightGoal" id="weightGoalInlineRadio3" value="gain" onChange={handleChange} checked={formData.weightGoal === 'gain'}  />
                      <label className="form-check-label" for="weightGoalInlineRadio3">Gain Weight</label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div>
                <h4 className="mb-3 h5">Step 3: Medical Information</h4>
                <div className="mb-3">
                  <label htmlFor="medicalCondition" className="form-label">What's your medical condition:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="medicalCondition"
                    name="medicalCondition"
                    value={formData.medicalCondition}
                    onChange={handleChange}
                    placeholder="E.g., Depression, Diabetes, Acne"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="allergies" className="form-label">What's your allergies:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="allergies"
                    name="allergies"
                    value={formData.allergies}
                    onChange={handleChange}
                    placeholder="E.g., Peanuts, Eggs, Wheat"
                  />
                </div>
              </div>
            )}

            {currentStep === 4 && (
              <div>
                <h4 className="mb-3 h5">Step 4: Dietary Preferences</h4>
                <div className="mb-3">
                  <label htmlFor="diet" className="form-label">What's your diet type:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="diet"
                    name="diet"
                    value={formData.diet}
                    onChange={handleChange}
                    placeholder="E.g. Vegetarian, Vegan, Keto, Gluten-Free"
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="diet" className="form-label m-0">Other information:</label>
                  <div><small>Please select checkboxes according to your preferences.</small></div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="proteinIntake"
                      name="proteinIntake"
                      checked={formData.proteinIntake}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="proteinIntake">
                      I am open to increasing my protein intake.
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="preservatives"
                      name="preservatives"
                      checked={formData.preservatives}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="preservatives">
                      I want to avoid foods that contain preservatives.
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="organicPreference"
                      name="organicPreference"
                      checked={formData.organicPreference}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="organicPreference">
                    I prefer to choose only organic products.
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="lowSugarSodium"
                      name="lowSugarSodium"
                      checked={formData.lowSugarSodium}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="lowSugarSodium">
                    I prefer foods with low amounts of added sugars and sodium
                    </label>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="avoidRefinedSugarSaturatedFat"
                      name="avoidRefinedSugarSaturatedFat"
                      checked={formData.avoidRefinedSugarSaturatedFat}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="avoidRefinedSugarSaturatedFat">
                    I want to avoid foods that contain refined sugar or saturated fat.
                    </label>
                  </div>
                </div>
                {/* Add more dietary preference checkboxes here */}
              </div>
            )}

            <div className="d-flex justify-content-between mt-4 gap-4">
              {currentStep > 1 && (
                <button type="button" className="btn btn-secondary previous-button" onClick={prevStep}>
                  <FaArrowLeft className='me-2' /> Previous
                </button>
              )}
              {currentStep < 4 ? (
                <button type="button" className="btn btn-primary next-button" onClick={nextStep}>
                  Next <FaArrowRight className='ms-2' />
                </button>
              ) : (
                <button type="submit" className="btn btn-success next-button">
                  Submit <FaArrowRight className='ms-2' />
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfileForm;