/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import FeedbackForm from './FeedbackForm';
import apiServiceInstance from './../services/load';
import SessionService from './../services/session';
import './Uploader.scss';
import { useAnalytics } from './AnalyticsTracker'; 

function Uploader({ formData, handleBackToEditProfile }) {
  const { trackEvent } = useAnalytics();
  const [apiResponse, setApiResponse] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const cameraContainerRef = useRef(null);

  useEffect(() => {
    if (showCamera) {
      startCamera();
    } else {
      stopCamera();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCamera]);

  const handleImageUpload = async (e) => {
    trackEvent('UploaderComponent', 'ImageUpload', 'ImageUpload', '');
    const file = e.target.files[0];
    if (file && file.size <= 1024 * 1024) { // 1MB limit
      setImageUrl(URL.createObjectURL(file)); // Set the image URL
      processImage(file);
    } else {
      alert('Please select an image smaller than 1MB');
    }
  };

  const processImage = async (file) => {
    setIsLoading(true);
    setError(null);
    setApiResponse(null);

    const formD = new FormData();
    formD.append('file', file);
    
    // Append form data to the request
    Object.keys(formData).forEach(key => {
      formD.append(key, formData[key]);
    });

    try {
      if (SessionService.isLoggedIn()) {
        const data = await apiServiceInstance.makePurchaseDecisionByUser(formD);
        setApiResponse(data);
      } else {
        const data = await apiServiceInstance.makePurchaseDecision(formD);
        setApiResponse(data);
      }
    } catch (err) {
      console.error('Error processing image:', err);
      let message = 'An error occurred while processing the image. Please try again.';
      if (err.response?.data?.error) {
        message = err.response?.data?.error;
      }
      setError(message);
      fileInputRef.current.value = '';
    } finally {
      setIsLoading(false);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      canvasRef.current.toBlob((blob) => {
        setImageUrl(URL.createObjectURL(blob)); // Set the image URL for the captured image
        processImage(blob);
      }, 'image/jpeg');
      setShowCamera(false);
    }
  };

  const startCamera = async () => {
    trackEvent('UploaderComponent', 'CameraStarted', 'CameraStarted', '');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: { facingMode: 'environment' } 
      });
      streamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setShowCamera(true);
      
      // Scroll to camera container after a short delay to ensure it's rendered
      setTimeout(() => {
        if (cameraContainerRef.current) {
          cameraContainerRef.current.scrollIntoView({ 
            behavior: 'smooth', 
            block: 'center'
          });
        }
      }, 100);
    } catch (err) {
      console.error("Error accessing the camera: ", err);
      alert("Unable to access the camera. Please make sure you've granted the necessary permissions.");
      setShowCamera(false);
    }
  };

  const stopCamera = () => {
    trackEvent('UploaderComponent', 'CameraStopped', 'CameraStopped', '');
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  return (
    <div className="saved-data">
      <div className='card'>
        <div className='card-body'>
          <h3 className='h4 text-dark'>Scan or Upload Food Products</h3>
          <div className="profile-header">
            <div><strong>You Profile</strong></div>
            <div>
            <p>Age: {formData.age}, Gender: {formData.gender}</p>
            </div>
            <a href={undefined} className="link-underline-dark edit-icon" onClick={(e) => handleBackToEditProfile(e)} aria-label="Edit profile">
              Edit Profile
            </a>
          </div>
          <div className="image-upload-options">
            {!SessionService.isLoggedIn() && (
            <div className="upload-hint">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/>
              </svg>
              <span>Upload or capture an image of a food product to get personalized recommendations. In case of packaged food, capture ingredients for better results.</span>
            </div>
            )}
            <div className="file-upload-container">
              <div className="file-upload">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <button onClick={() => fileInputRef.current.click()} className="upload-button">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M9 16h6v-6h4l-7-7-7 7h4v6zm-4 2h14v2H5v-2z"/>
                  </svg>
                  <span>Upload Image</span>
                </button>
              </div>
              <div className="camera-upload">
                <button onClick={() => setShowCamera(true)} className="camera-button">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path d="M9 3L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2h-3.17L15 3H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"/>
                    <circle cx="12" cy="13" r="3"/>
                  </svg>
                  <span>Take Photo</span>
                </button>
              </div>
            </div>
          </div>
          {showCamera && (
            <div className="camera-container" ref={cameraContainerRef}>
              <p className="hover-tip text-muted small fst-italic">
                Point your camera at any food product's ingredients or packaging
              </p>
              <video ref={videoRef} autoPlay playsInline />
              <div className="camera-controls">
                <button onClick={captureImage}>Capture</button>
                <button onClick={() => setShowCamera(false)}>Cancel</button>
              </div>
              <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
            </div>
          )}
        </div>
        {imageUrl && (
          <div className="image-preview text-center">
            <img src={imageUrl} alt="Uploaded Food Product" />
          </div>
        )}
        {isLoading && <div className="loading">Processing image...</div>}
        {error && <div className="error">{error}</div>}
        {apiResponse && (
          <div>
            <div className={`api-response m-4 ${apiResponse.purchase_decision}`}>
              <h2 className="decision">
                Purchase Decision: {apiResponse.purchase_decision.toUpperCase()}
              </h2>
              <div className="score-container">
                <div className="score-bar" style={{width: `${apiResponse.purchase_decision_score * 10}%`}}></div>
                <span className="score-text">{apiResponse.purchase_decision_score}/10</span>
              </div>
              <p className="reason">{apiResponse.reason}</p>
            </div>
            {!SessionService.isLoggedIn() && (
            <div className='text-center'>
              <FeedbackForm isFixed={false} customText='Would you mind sharing your feedback?' />
            </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Uploader;
