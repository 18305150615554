class SessionService {
    // Key for local storage
  static USER_KEY = 'auth';

  // Check if the user is logged in
  static isLoggedIn() {
    const user = localStorage.getItem(this.USER_KEY);
    return user !== null; // Returns true if user data exists
  }

  // Get user ID from the session
  static getUserId() {
    const user = this.getUserProfile();
    return user ? user.id : null;
  }

  static getUserAuthToken() {
    const user = this.getUserProfile();
    return user ? user.token : null;
  }

  static isAuthTokenExpired() {
    const user = this.getUserProfile();
    if (!user) {
        return true;
    }
    return new Date(user.expires).getTime() <= Date.now();
  }

  // Get user profile from the session
  static getUserProfile() {
    const user = localStorage.getItem(this.USER_KEY);
    return user ? JSON.parse(user) : null; // Parse and return user data or null
  }

  // Save user profile to the session
  static saveUserProfile(user) {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user)); // Store user data as a string
  }

  // Clear user session (logout)
  static logout() {
    localStorage.removeItem(this.USER_KEY); // Remove user data from local storage
  }
}

export default SessionService;