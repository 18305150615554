import SessionService from './../services/session';
import config from '../config';
import { BiSolidUserAccount } from "react-icons/bi";
import { useAnalytics } from './AnalyticsTracker'; 

function HeaderMenu() {
    const { trackEvent } = useAnalytics();

    const handleGoogleSignIn = () => {
        // Implement your Google sign-in logic here
        console.log('Signing in with Google...');
        // eslint-disable-next-line no-undef
        trackEvent('HeaderMenu', 'GoogleSignIn', 'GoogleSignIn', '');
        window.location.href = `${config.apiUrl}/google/auth?redirect=true`;
    };

    return (
        <div className="header-menu">
        <div className="logo">
          <a href='/'>
          <img src='/logo.png' alt='FoodFitAi Logo'></img>
          </a>
        </div>
        {!(SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired()) && (
        <div>
            <button className="header-button sing-in-button" onClick={() => handleGoogleSignIn()}>
            Start using FoodFitAi
            </button>
            <button type='button' className="header-button-icon btn btn-outline-primary" onClick={() => handleGoogleSignIn()}><BiSolidUserAccount /></button>
        </div>
        )}
        {SessionService.isLoggedIn() && !SessionService.isAuthTokenExpired() && (
        <div>
            <button className="header-button sing-in-button" onClick={() => window.location.href = '/user'}>
            Access your account 
            </button>
            <button type='button' className="header-button-icon btn btn-outline-primary" onClick={() => window.location.href = '/user'}><BiSolidUserAccount /></button>
        </div>
        )}
        
      </div>
    );
}

export default HeaderMenu;