function FooterSection() {
    return (
        <div>
            <p className='m-0 mt-2'>
                    <span>Links: </span>
                    <a href='/privacy-policy' className='me-2'>Privacy Policy</a>
                    <a href='/terms-conditions' className='me-2'>Terms & Conditions</a>
                    <a href='/download-app' className='me-2'>Download App</a>
                </p>
                <p className='mt-0'>
                  © 2024 MatteCurve Technologies Pvt. Ltd.
                </p>
        </div>
    );
}

export default FooterSection;