class UserProfileDataService {
    // Key for local storage
  static USER_KEY = 'user_profile';

  static getUserEmail() {
    const user = this.getUserProfile();
    return user ? user.email.address : null;
  }

  static getUserAvailableCredits() {
    const user = this.getUserProfile();
    return user ? user.credits.total - user.credits.used : 0;
  }

  // Get user profile from the session
  static getUserProfile() {
    const user = localStorage.getItem(this.USER_KEY);
    return user ? JSON.parse(user) : null; // Parse and return user data or null
  }

  // Save user profile to the session
  static saveUserProfile(user) {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user)); // Store user data as a string
  }

  static removeUserProfile() {
    localStorage.removeItem(this.USER_KEY);
  }
}

export default UserProfileDataService;